.panel-pane {
    @include media($medium-screen-up) {
        @include span-columns(4);
    }
    margin-bottom: 5rem;
}
.ethos-block,
.factum-block {
    text-align: center;
    @include media($medium-screen-up) {
        @include span-columns(2);
    }
    svg {
        height: 75px;
        width: auto;
        margin-bottom: 2rem;
    }
    .titelbild img {
        max-height: 400px;
        width: auto;
        box-shadow: 0px 0px 20px 0px $medium-gray;
        transform: rotate(5deg);
        margin: 2rem 0 1rem;
    }
}
.ethos-block {
    margin-bottom: 80px;
    @include media($medium-screen-up) {
        @include omega;
        padding-right: 30px;
        margin-bottom: 0px;
        border: 5px dotted #808080;
        border-image-source: url('../img/border-dots-square.svg');
	    border-image-slice: 25% 25%;
	    border-image-repeat: round;
	    border-left: 0;
	    border-bottom: 0;
	    border-top: 0;
    }
}
.factum-block {
    @include media($medium-screen-up) {
        @include omega();
        padding-left: 30px;
    }
}
.front .pane-title,
#page-title {
    font-family: $heading-font-family;
    font-size: $base-font-size*2.5;
    margin: 0 0 $base-spacing;
    font-weight: 400;
    text-align: center;
    position: relative;
    padding: 60px 20px 0px;
    &::after {
        position: absolute;
        left: 0px;
        top: 0px;
        content: ' ';
        width: 100%;
        height: 200px;
        z-index: -10;
        background: linear-gradient(to bottom, #f2f2f2 0%, #fff 100%);
    }
}