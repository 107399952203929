#site-footer {
  @include clearfix;
  color: #fff;
  a {
    color: #fff;
  }
  .footer-top {
    background-color: $medium-gray;
    padding: 2.5em 0em 1.5em;
    section  {
      @include outer-container;
      >div {
        @include span-columns(4);
        margin-bottom: 1em;
      }
    }
  }
  .footer-bottom {
    background-color: $dark-gray;
    text-align: center;
    padding: 1em;
    section  {
      @include outer-container;
    }
  }
}