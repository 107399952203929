@import "../../bower_components/neat/app/assets/stylesheets/neat-helpers";
// Neat Overrides
// $column: 90px;
// $gutter: 30px;
// $grid-columns: 12;
$max-width: em(1088);

// Neat Breakpoints
$medium-screen: em(640);
$large-screen: em(860);
$larger-screen: em(1024);

$medium-screen-up: new-breakpoint(min-width $medium-screen 4);
$large-screen-up: new-breakpoint(min-width $large-screen 8);
$larger-screen-up: new-breakpoint(min-width $larger-screen 8);

// breakpoints
   
$S:     480px;   
$M:     768px;     
$L:     1100px;     

// media queries

@mixin MQ($canvas) {
  @if $canvas == S {
   @media only screen and (min-width: $S) { @content; } 
  }
  @else if $canvas == M {
   @media only screen and (min-width: $M) { @content; } 
  }
  @else if $canvas == L {
   @media only screen and (min-width: $L) { @content; } 
  }
}

