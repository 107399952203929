header {
    position: relative;
    z-index: 200;
    padding: 20px 0;
    box-shadow: 0px 6px 6px 0px rgba(0,0,0,0.15);
}
.block-logo {
    text-align: center;
    img {
        width: 70%;
    }
    @include media($medium-screen-up) {
        img {
            width: 50%;
        }
    }
    @include media($large-screen-up) {
        float: right;
        width: 350px;
        padding: 0 0 20px 50px;
        img {
            width: 100%;
        }
    }
}
.block-menu {
    text-align: center;
    margin-top: 40px;
    li {
        display: inline-block;
        margin-right: 20px;
        a {
            color: $medium-gray;
            &:hover {
                color: $blue;
            }
        }
        &:last-child {
            margin-right: 0px;
        }
    }
    @include media($medium-screen-up) {
        li {
            margin-right: 40px;
        }
    }
    @include media($large-screen-up) {
        margin-top: 90px;
        float: left;
        text-align: left;
        li {
            margin-right: 60px;
        }
    }
}

#featured {
    .slick-slide {
        position: relative;
        border: 0 none;
        overflow: hidden;
        .views-field-field-titelbild .field-content div {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 300px;
            @include media($medium-screen-up) {
                height: 400px;
            }
            @include media($large-screen-up) {
                height: 500px;
            }
            @include media($larger-screen-up) {
                height: 600px;
            }
        }
        .views-field-field-titelseite {
            position: absolute;
            top: 0px;
            z-index: 99;
            height: 100%;
            width: 100%;
            .field-content {
                @include outer-container;
                img {
                    float: right;
                    width: 150px;
                    margin: 40px 20px;
                    transform: rotate(10deg);
                    box-shadow: 3px 5px 20px 0px #000;
                    @include media($medium-screen-up) {
                        width: 200px;
                        margin: 75px 30px;
                    }
                    @include media($large-screen-up) {
                        width: 250px;
                        margin: 100px 40px;
                    }
                    @include media($larger-screen-up) {
                        width: 300px;
                        margin: 100px 40px;
                    }
                }
            }
        }
    }
}